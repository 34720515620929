import React from "react";

/**
 * A component designed to selct between 2 or 3 elements
 * @param {[Object]} options - The array of options from which ua user could select. each element in the the arrayt should be  {label:"",value:""}
 * @param {string} defaultValue -  The value selected by default
 *  @param {function} onChange - the onchange function
 */
const TabRadioButton = ({ options, onChange, defaultValue }) => {
  return (
    <div className="flex">
      {options.map((item, index) => {
        return (
          <button
            key={`${item.value}`}
            onClick={() => {
              onChange(item.value);
            }}
            className={`flex items-center border ${
              index === 0 && "rounded-l-md"
            } ${
              index === options.length - 1 && "rounded-r-md"
            }  laptop:p-2 mobile:p-1  focus:outline-none desktop:text-base mobile:text-sm ${
              defaultValue === item.value
                ? "bg-white text-violet-700 border-violet-700 font-bold "
                : "text-gray-500 bg-gray-100   border-gray-300"
            } ${item.disabled && "pointer-events-none saturate-0 opacity-50"}`}
          >
            <span>{item.label}</span>
          </button>
        );
      })}
    </div>
  );
};

export default TabRadioButton;
