import React from "react";
import AuthorisedApp from "./AuthorisedApp";
import UnauthorisedApp from "./UnauthorisedApp";
import { useAuth } from "./contexts/AuthenticationContext";
import { useWorkspace } from "./contexts/WorkspaceContext";
import ThankyouPage from "./previews/ThankyouPage";
// import ThankyouPage from "./previews/ThankyouPage";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

function App() {
  const authState = useAuth();
  const workspace = useWorkspace();

  return (
    <Router>
      <Switch>
        <Route exact path="/preview/thankyou-page" component={ThankyouPage} />
        {authState.status === "in" && workspace.data ? (
          <Route path="/" component={AuthorisedApp} />
        ) : (
          <Route path="/" component={UnauthorisedApp} />
        )}
      </Switch>
    </Router>
  );
}
export default App;
