import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import { RiFlagFill, RiAddCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik, FieldArray } from "formik";
import toaster from "toasted-notes";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import PercentageAmountInput from "../../../../components/PercentageAmountInput";
import { init } from "@sentry/browser";

export default function EditCartGoals() {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const [initialValues, setInitialValues] = useState();
  const [expandedGoals, setExpandedGoals] = useState(0);

  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  useEffect(() => {
    let defaultCartGoalValues = {
      isActive: false,
      goalType: "totalCartValue",
      goals: [
        {
          goalAmount: 50,
          rewardType: "freeShipping",
          rewardDetails: {
            type: "percentage",
            value: 20,
          },
          content: {
            preGoalText: "Add <goal> more to get free shipping",
            postGoalText: "You got free shipping!",
          },
        },
      ],
    };
    if (data && data.widgets_cornerwidget[0].cart_config.cartGoals)
      setInitialValues(data.widgets_cornerwidget[0].cart_config.cartGoals);
    else setInitialValues(defaultCartGoalValues);
  }, [data]);

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const GoalTypeOptions = [
    { label: "Cart Value", value: "totalCartValue" },
    { label: "Cart item count", value: "cartItemCount" },
    // { label: "Cart Weight", value: "cartWeight" },
  ];

  const rewardOptions = [
    { label: "Free Shipping", value: "freeShipping" },
    { label: "Discount (Coming Soon)", value: "discount", disabled: true },
    { label: "Free Item  (Coming Soon)", value: "freeItem", disabled: true },
  ];

  if (initialValues)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          updateWidgetMutation({
            id: { id: data.widgets_cornerwidget[0].id },
            config: {
              cart_config: {
                ...data.widgets_cornerwidget[0].cart_config,
                cartGoals: { ...values },
              },
            },
          });
        }}
      >
        {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
          return (
            <PageViewContainer title="Cart Goals">
              {dirty && (
                <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                  <p>You've unsaved changes</p>
                  <div className="flex items-center">
                    <Button
                      onClick={() => {
                        resetForm();
                      }}
                      type="link"
                    >
                      Discard Changes
                    </Button>
                    <Button
                      onClick={() => {
                        submitForm();
                      }}
                      type="primary"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
              <div className=" h-full flex flex-col overflow-y-auto px-8 py-4 pb-12">
                <CustomiserUnit
                  title="Enable Cart Goals"
                  description="Choose whether to show cart goals in your cart widget"
                >
                  <TabRadioButton
                    defaultValue={values.isActive}
                    options={visibilityOptions}
                    onChange={(selectedType) => {
                      setFieldValue("isActive", selectedType);
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Goal Type"
                  description="Define based on what the goal is defined"
                >
                  <TabRadioButton
                    defaultValue={values.goalType}
                    options={GoalTypeOptions}
                    onChange={(selectedType) => {
                      setFieldValue("goalType", selectedType);
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Cart Goals"
                  description="Customise Cart Goals"
                >
                  <FieldArray
                    name="goals"
                    render={(arrayHelpers) => {
                      return (
                        <div>
                          <div>
                            {values.goals.map((goal, index) => (
                              <div className="rounded-lg border mt-3">
                                <div className="flex items-center justify-between w-full border-b px-4">
                                  <button
                                    onClick={() => {
                                      if (expandedGoals !== index)
                                        setExpandedGoals(index);
                                      else setExpandedGoals(null);
                                    }}
                                    className="flex flex-grow items-center p-4 "
                                  >
                                    <div className="flex justify-center items-center mr-2  rounded-full h-8 w-8 bg-violet-100 text-violet-600 rounded-full">
                                      <RiFlagFill />
                                    </div>
                                    <h2 className="font-bold text-lg">
                                      Goal{" "}
                                      {values.goals.length > 1 && index + 1}
                                    </h2>
                                  </button>
                                  <div className="flex items-center">
                                    <Button
                                      onClick={() => {
                                        if (expandedGoals !== index)
                                          setExpandedGoals(index);
                                        else setExpandedGoals(null);
                                      }}
                                      type="link"
                                    >
                                      {expandedGoals !== index
                                        ? "Expand"
                                        : "Collapse"}
                                    </Button>

                                    {index !== 0 && (
                                      <Button
                                        onClick={() => {
                                          if (expandedGoals === index) {
                                            setExpandedGoals(index - 1);
                                          }
                                          arrayHelpers.remove(index);
                                        }}
                                        type="ghost"
                                        icon={<RiDeleteBin6Line />}
                                      >
                                        Delete
                                      </Button>
                                    )}
                                  </div>
                                </div>
                                {expandedGoals === index && (
                                  <div className="p-4 pl-9">
                                    <CustomiserUnit
                                      title="Spend Goal"
                                      description="Define based on what the goal is defined"
                                    >
                                      <div className="flex items-center">
                                        <div className=" w-20">
                                          <Textfield
                                            value={goal.goalAmount}
                                            onChange={(onChangeEvent) => {
                                              if (
                                                onChangeEvent.target.value < 1
                                              ) {
                                                toaster.notify(
                                                  "The value must be greater than 0",
                                                  {
                                                    duration: 2000,
                                                  }
                                                );
                                              }
                                              // The following condition makes sure the value doesnt increase than the next stage
                                              else if (
                                                values.goals.length >
                                                  index + 1 &&
                                                onChangeEvent.target.value >=
                                                  values.goals[index + 1]
                                                    .goalAmount
                                              ) {
                                                toaster.notify(
                                                  `The value must be lower than the spend goal of the next goal`,
                                                  {
                                                    duration: 2000,
                                                  }
                                                );
                                              }
                                              // The following condition makes sure the value doesnt decrease than the previous stage
                                              else if (
                                                index > 0 &&
                                                onChangeEvent.target.value <=
                                                  values.goals[index - 1]
                                                    .goalAmount
                                              ) {
                                                toaster.notify(
                                                  "The value must be greater than the spend goal of the previous goal",
                                                  {
                                                    duration: 2000,
                                                  }
                                                );
                                              }
                                              // And finally if both of the condition above fail the value is set
                                              else {
                                                setFieldValue(
                                                  `goals[${index}].goalAmount`,
                                                  parseInt(
                                                    onChangeEvent.target.value,
                                                    10
                                                  )
                                                );
                                              }
                                            }}
                                            name="basic"
                                            type="number"
                                            min={
                                              index !== 0
                                                ? values.goals[index - 1]
                                                    .goalAmount + 1
                                                : 1
                                            }
                                          />
                                        </div>
                                        <span className="ml-1 font-bold">
                                          {values.goalType === "totalCartValue"
                                            ? workspace.data.storeCurrency
                                            : "Items"}
                                        </span>
                                      </div>
                                    </CustomiserUnit>

                                    <CustomiserUnit
                                      title="Reward"
                                      description="Define the reward the customer gets for achieving the cart goal"
                                    >
                                      <div className="">
                                        <div className="mr-4 mb-3">
                                          <TabRadioButton
                                            defaultValue={goal.rewardType}
                                            options={rewardOptions}
                                            onChange={(selectedType) => {
                                              setFieldValue(
                                                `goals[${index}].rewardType`,
                                                selectedType
                                              );
                                            }}
                                          />
                                          {goal.rewardType ===
                                            "freeShipping" && (
                                            <p className="py-3 px-2  rounded-lg text-amber-800  mt-3 bg-amber-100 flex items-center justify-between">
                                              <span>
                                                {" "}
                                                Make sure the free shipping is
                                                already setup in your shopify
                                                admin
                                              </span>
                                              <a
                                                className="font-bold mx-3"
                                                target="_blank"
                                                href="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/setting-up-shipping-rates#free-shipping-over-a-certain-value"
                                              >
                                                Learn how
                                              </a>
                                            </p>
                                          )}
                                        </div>
                                        {goal.rewardType === "discount" && (
                                          <div className="flex items-center border rounded-lg p-3 max-w-sm">
                                            <p className="mr-4">
                                              Discount amount
                                            </p>
                                            <PercentageAmountInput
                                              amount={goal.rewardDetails.value}
                                              dimention={
                                                goal.rewardDetails.type
                                              }
                                              onAmountChange={(value) => {
                                                setFieldValue(
                                                  `goals[${index}].rewardDetails.value`,
                                                  value
                                                );
                                              }}
                                              onDimentionChange={(value) => {
                                                setFieldValue(
                                                  `goals[${index}].rewardDetails.type`,
                                                  value
                                                );
                                              }}
                                              currency={
                                                workspace.data.storeCurrency
                                              }
                                            />
                                          </div>
                                        )}
                                        {goal.rewardType === "freeItem" && (
                                          <Button
                                            onClick={() => {
                                              console.log(`lolo ----> `);
                                            }}
                                            type="primary"
                                          >
                                            Select Product
                                          </Button>
                                        )}
                                      </div>
                                    </CustomiserUnit>

                                    <CustomiserUnit
                                      title="Text Before Achieving the Goal"
                                      description="Define the text shown above the meter before achieving the goal"
                                    >
                                      <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                                        <div className="">
                                          <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                            &lt;goal&gt;
                                          </span>{" "}
                                          : Will be replaced to the total order
                                          value needed to get the reward.
                                        </div>
                                        <div className="mt-3">
                                          <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                            &lt;current_status&gt;
                                          </span>{" "}
                                          : Will be replaced to show the current
                                          cart value
                                        </div>
                                      </div>
                                      <Textfield
                                        value={goal.content.preGoalText}
                                        onChange={(onChangeEvent) => {
                                          setFieldValue(
                                            `goals[${index}].content.preGoalText`,
                                            onChangeEvent.target.value
                                          );
                                        }}
                                        name="basic"
                                      />
                                    </CustomiserUnit>
                                    <CustomiserUnit
                                      title="Text After Achieving the Goal"
                                      description="Define the text shown above the meter after achieving the goal"
                                    >
                                      <div className="border rounded-md bg-gray-100 text-sm p-3 mb-3">
                                        <div className="">
                                          <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                            &lt;goal&gt;
                                          </span>{" "}
                                          : Will be replaced to the total order
                                          value needed to get the reward.
                                        </div>
                                        <div className="mt-3">
                                          <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                            &lt;current_status&gt;
                                          </span>{" "}
                                          : Will be replaced to show the current
                                          cart value
                                        </div>
                                      </div>
                                      <Textfield
                                        value={goal.content.postGoalText}
                                        onChange={(onChangeEvent) => {
                                          setFieldValue(
                                            `goals[${index}].content.postGoalText`,
                                            onChangeEvent.target.value
                                          );
                                        }}
                                        name="basic"
                                      />
                                    </CustomiserUnit>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {/* Add New Goal Button */}
                          {/* {values.goals.length < 3 && (
                            <button
                              onClick={() => {
                                const defaultGoal = {
                                  goalAmount:
                                    values.goals[values.goals.length - 1]
                                      .goalAmount + 50,
                                  rewardType: "discount",
                                  rewardDetails: {
                                    type: "percentage",
                                    value:
                                      values.goals[values.goals.length - 1]
                                        .rewardDetails.value + 10,
                                  },
                                  content: {
                                    preGoalText:
                                      "Add <goal> more to get <reward> Off",
                                    postGoalText: "You got <reward> Off",
                                  },
                                };
                                arrayHelpers.push(defaultGoal);
                                setExpandedGoals(values.goals.length);
                              }}
                              className="w-full text-center py-6 border rounded-lg mt-4 font-bold flex items-center justify-center text-violet-700 transition-all hover:border-0 hover:bg-indigo-100"
                            >
                              <RiAddCircleLine />
                              <span className="ml-1">Add a New Goal</span>
                            </button>
                          )} */}
                        </div>
                      );
                    }}
                  />
                </CustomiserUnit>
              </div>
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
}
