import React, { Children } from "react";
import Textfield from "@atlaskit/textfield";
import toaster from "toasted-notes";
import Select from "@atlaskit/select";

/**
 * An UI element to get either a currency value or a percentage value
 * @component
 * @param {function} onAmountChange - what happens on amount change
 * @param {function} onDimentionChange - what happens on dimention change
 * @param {number} amount - the amount
 * @param {number} dimention - percentage or amount
 * @param {string} currency - Currency

 */
const PercentageAmountInput = ({onAmountChange,onDimentionChange,amount,dimention,currency}) => {
  const dimensionOptions = [
    { label: "%", value: "percentage" },
    { label: `${currency}`, value: "fixedAmount" },
  ];
  return (
    <div className="flex items-center ">
      <div className="w-20">
        <Textfield
          value={amount}
          onChange={(onChangeEvent) => {
            if (onChangeEvent.target.value < 1) {
              toaster.notify("The value must be greater than 0", {
                duration: 2000,
              });
            } else if (
              onChangeEvent.target.value > 100 &&
              dimention === "percentage"
            ) {
              toaster.notify("You cant give a dicount more than 100%", {
                duration: 2000,
              });
            } else {
              onAmountChange(
                parseInt(onChangeEvent.target.value, 10) * -1
              );
            }
          }}
          min={1}
          type="number"
          name="basic"
        />
      </div>
      <Select
        isSearchable={false}
        value={dimensionOptions.find(
          (option) => option.value === dimention
        )}
        onChange={(onChangeEvent) => {
          // The following condition makes sure the percentage doesnt exceeds 100 when switching from fixedAmount. If it exceded discount value is set to 20% by default
          if (
            amount > 100 &&
            onChangeEvent.value === "percentage"
          ) {
            onDimentionChange(-20);
          }
          onDimentionChange(onChangeEvent.value);
        }}
        className="w-24 "
        options={dimensionOptions}
      />
    </div>
  );
};

export default PercentageAmountInput;
