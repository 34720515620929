import React, { useState, useEffect } from "react";
import toaster from "toasted-notes";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import ArrayInput from "../../../../components/ArrayInput";
import Button from "../../../../components/Button";
import { Formik, FieldArray } from "formik";
import ShopifyProductPicker from "../../../../components/ShopifyProductPicker";
import TagGroup from "@atlaskit/tag-group";
import Tag from "@atlaskit/tag";

const VisibilityOptions = (props) => {
  const initialValues = props.value;
  const [viewState, setViewState] = useState();
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (props.value.viewInAll) setViewState("all");
    else if (
      !props.value.viewInAll &&
      props.value.product.length === 0 &&
      props.value.tags.length === 0
    )
      setViewState("none");
    else setViewState("some");
  }, []);

  // to manage the products selected
  const handleProductSelection = (selectedProducts, formikProps) => {
    let prodArray = [];
    selectedProducts.map((product) => {
      prodArray.push(product.handle);
    });
    formikProps.setFieldValue("product", prodArray);
    setProductList(selectedProducts);
  };

  const handleRemoveProduct = (removedHandle, formikProps) => {
    setProductList([]);
    let prodArray = formikProps.values.product.filter(
      (prodHandle) => prodHandle !== removedHandle
    );
    formikProps.setFieldValue("product", prodArray);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        let output = {
          viewInAll: values.viewInAll,
          product: values.product,
          tags: values.tags,
        };
        props.onSubmit(output);
        props.onClose();
      }}
    >
      {(formikProps) => {
        return (
          <div className="p-6">
            <div className="pb-8">
              <h1 className="mb-3">Link FAQ to products</h1>
              <p>
                Using the following settings determine in which all product
                pages this particular article should be visible in the accordion
              </p>
              <div className="flex mt-3">
                <button
                  onClick={() => {
                    formikProps.setFieldValue("viewInAll", false);
                    formikProps.setFieldValue("product", []);
                    formikProps.setFieldValue("tags", []);
                    setViewState("none");
                  }}
                  className={`flex items-center border rounded-l-md text-gray-600 bg-gray-100   border-gray-300 laptop:px-3 mobile:px-1 py-3 focus:outline-none hover:shadow-lg laptop:text-base mobile:text-sm ${
                    viewState === "none" &&
                    "bg-indigo-100 text-violet-700 border-blue-200 font-bold"
                  }`}
                >
                  <span>Dont show in product pages</span>
                </button>
                <button
                  onClick={() => {
                    formikProps.setFieldValue("viewInAll", true);
                    setViewState("all");
                  }}
                  className={`flex items-center border  text-gray-600 bg-gray-100   border-gray-300 laptop:px-3 mobile:px-1 py-3 focus:outline-none hover:shadow-lg laptop:text-base mobile:text-sm ${
                    viewState === "all" &&
                    "bg-indigo-100 text-violet-700 border-blue-200 font-bold"
                  }`}
                >
                  <span>Show in all products</span>
                </button>
                <button
                  onClick={() => {
                    formikProps.setFieldValue("viewInAll", false);
                    setViewState("some");
                  }}
                  className={`flex items-center border rounded-r-md text-gray-600 bg-gray-100   border-gray-300 laptop:px-3 mobile:px-1 py-3 focus:outline-none hover:shadow-lg laptop:text-base mobile:text-sm ${
                    viewState === "some" &&
                    "bg-indigo-100 text-violet-700 border-blue-200 font-bold"
                  }`}
                >
                  <span>Show in some products</span>
                </button>
              </div>
            </div>
            {viewState === "some" && (
              <div>
                <CustomiserUnit
                  title="Product URL"
                  description="Add the URLs of product pages in which you want this FAQ to appear. (Add one url at a time and press the button 'Add product URL') "
                >
                  <FieldArray
                    name="product"
                    render={(arrayHelpers) => (
                      <ArrayInput
                        urlCheck
                        addButtonText="Add Product URL"
                        onAddition={(value) => {
                          if (value.includes("/products/")) {
                            let productHandle = value.match(/([^\/]*)\/*$/)[1];
                            arrayHelpers.push(productHandle);
                          } else {
                            toaster.notify(
                              "Invalid Product URL: Make sure the url has the word 'products' inside it",
                              {
                                duration: 2000,
                              }
                            );
                          }
                        }}
                        onDeletion={(index) => arrayHelpers.remove(index)}
                        arrayItems={
                          formikProps.values.product
                            ? formikProps.values.product
                            : []
                        }
                      />
                    )}
                  />
                </CustomiserUnit>
                {/* <CustomiserUnit
                  title="Select Products"
                  description="Please click on the button below and select the required products from the popup"
                >
                  <ShopifyProductPicker
                    products={productList}
                    onChange={(selected) =>
                      handleProductSelection(selected, formikProps)
                    }
                  />
                  <TagGroup>
                    {formikProps.values.product.map(
                      (productHandleString, index) => (
                        <div className="my-2" key={index}>
                          <Tag
                            onAfterRemoveAction={() =>
                              handleRemoveProduct(
                                productHandleString,
                                formikProps
                              )
                            }
                            key={Math.random()}
                            text={productHandleString}
                          />
                        </div>
                      )
                    )}
                  </TagGroup>
                </CustomiserUnit> */}

                <CustomiserUnit
                  title={<span>Link with product tags </span>}
                  description="Add tags in the text box below. If any one of these tags are assigned to your product the this FAQ will be shown in that product page. (Add one tag at a time and press the button 'Add tag')"
                >
                  <FieldArray
                    name="tags"
                    render={(arrayHelpers2) => (
                      <ArrayInput
                        addButtonText="Add Tag"
                        onAddition={(value) => {
                          arrayHelpers2.push(value);
                        }}
                        onDeletion={(index) => arrayHelpers2.remove(index)}
                        arrayItems={
                          formikProps.values.tags ? formikProps.values.tags : []
                        }
                      />
                    )}
                  />
                </CustomiserUnit>
              </div>
            )}
            <div className="flex justify-end items-center">
              <Button
                className="ml-3"
                type="link"
                onClick={() => {
                  formikProps.resetForm();
                  props.onClose();
                }}
              >
                {" "}
                Discard changes
              </Button>
              <Button type="primary" onClick={() => formikProps.submitForm()}>
                {" "}
                Continue
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default VisibilityOptions;
