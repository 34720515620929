import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import {
  RiStore2Fill,
  RiFullscreenFill,
  RiAwardFill,
  RiSettings3Fill,
  RiFullscreenExitFill,
} from "react-icons/ri";
import Button from "../Button";
import Tree from "@atlaskit/tree";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Fullscreen } from "@shopify/app-bridge/actions";

const SectionNav = () => {
  // const app = useAppBridge();
  // const fullscreen = Fullscreen.create(app);
  const workspace = useWorkspace();

  const faqSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2, 8],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4, 5, 6, 7],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "FAQ",
          type: "title",
          link: "/support/faq/articles/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Articles",
          type: "page",
          link: "/support/faq/articles/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Categories",
          type: "page",
          link: "/support/faq/categories/",
        },
      },
      5: {
        id: 5,
        children: [],
        data: {
          name: "FAQ Page",
          type: "page",
          link: "/support/faq/page/",
        },
      },
      6: {
        id: 6,
        children: [],
        data: {
          name: "Custom Accordions",
          type: "page",
          link: "/support/faq/custom-accordions/",
        },
      },
      7: {
        id: 7,
        children: [],
        data: {
          name: "Product Accordion",
          type: "page",
          link: "/support/faq/product-accordion/",
        },
      },
      8: {
        id: 8,
        children: [9, 10],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Widget",
          type: "title",
          link: "/support/widget/close-state/",
        },
      },
      9: {
        id: 9,
        children: [],
        data: {
          name: "Closed State",
          type: "page",
          link: "/support/widget/close-state/",
        },
      },
      10: {
        id: 10,
        children: [],
        data: {
          name: "Opened state",
          type: "page",
          link: "/support/widget/open-state/",
        },
      },
    },
  };
  const campaignSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Referral",
          type: "title",
          link: "/offers/referral/overview/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Campaigns",
          type: "page",
          link: "/offers/referral/campaigns/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Requests",
          type: "page",
          link: "/offers/referral/requests/",
        },
      },
    },
  };

  const cartSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2,5],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Widgets",
          type: "title",
          link: "/cart/widgets/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Cart Widget",
          type: "page",
          link: "/cart/widgets/corner-cart",
        },
      },
      4: {
        id: 4,
        children: [5],
        data: {
          name: "Add To Cart Bar ",
          type: "page",
          link: "/cart/widgets/atc-bar",
        },
      },
      5: {
        id: 5,
        children: [6],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Conversion Optimisers",
          type: "title",
          link: "/cart/cro/",
        },
      },
      6: {
        id: 6,
        children: [],
        data: {
          name: "Cart Goals",
          type: "page",
          link: "/cart/cro/cart-goals",
        },
      },
    },
  };

  const getLanguageSetingsSubSections = () => {
    let baseItems = [6];
    if (workspace.data.installedApps.find((item) => item.appid === 1)) {
      baseItems.push(7, 8);
    }
    if (workspace.data.installedApps.find((item) => item.appid === 2)) {
      baseItems.push(9, 10);
    }
    return baseItems;
  };

  const settingsSectionNavTree = {
    rootId: 1,
    items: {
      1: {
        id: 1,
        children: [2],
        hasChildren: true,
      },
      2: {
        id: 2,
        children: [3, 4, 5, 6],
        isExpanded: true,
        hasChildren: true,
        data: {
          name: "Settings",
          type: "title",
          link: "/settings/account/",
        },
      },
      3: {
        id: 3,
        children: [],
        data: {
          name: "Account",
          type: "page",
          link: "/settings/account/",
        },
      },
      4: {
        id: 4,
        children: [],
        data: {
          name: "Language",
          type: "page",
          link: "/settings/language",
        },
      },
      5: {
        id: 5,
        children: [],
        data: {
          name: "Integrations",
          type: "page",
          link: "/settings/integration",
        },
      },
      6: {
        id: 6,
        children: [],
        data: {
          name: "Billing",
          type: "page",
          link: "/settings/billing",
        },
      },
    },
  };

  const adSectionNavTree = {
    rootId: 1,
    items: {},
  };

  const [sectionNavTreeData, setSectionNavTreeData] = useState(
    settingsSectionNavTree
  );
  const [isFullscreen, setIsFullScreen] = useState(false);

  const SectionLink = ({ item }) => {
    let match = useRouteMatch(item.data.link);
    const isSelected = match?.path === item.data.link;
    return (
      <NavLink to={item.data.link} strict>
        <div
          className={`py-1 px-2 desktop:px-4  desktop:py-2 desktop:px-4 mt-2 rounded-lg font-medium ${
            isSelected &&
            item.data.type === "page" &&
            "bg-white bg-opacity-70 shadow-sm "
          } ${
            item.data.type === "page" && " hover:bg-white hover:bg-opacity-50"
          }`}
        >
          {item.data.type === "title" ? (
            <p className="text-sm desktop:text-base font-bold text-gray-500 ">
              {item.data.name}
            </p>
          ) : (
            <p
              className={`flex text-tiny desktop:text-base desktop:text-base items-center ${
                isSelected && "text-violet-700 "
              }`}
            >
              {/* {isSelected && (
                <span className="mr-1">
                  <RiArrowRightSLine />
                </span>
              )} */}
              <span className="">{item.data.name}</span>
            </p>
          )}
        </div>
      </NavLink>
    );
  };

  let location = useLocation();
  const pageRoute = location.pathname.split("/")[1];

  const handleScreenMaximise = () => {
    setIsFullScreen((currentState) => {
      if (currentState === true) fullscreen.dispatch(Fullscreen.Action.EXIT);
      else fullscreen.dispatch(Fullscreen.Action.ENTER);
      return !currentState;
    });
  };

  useEffect(() => {
    const initializeSidebar = () => {
      switch (pageRoute) {
        case "support": {
          setSectionNavTreeData(faqSectionNavTree);
          break;
        }
        case "settings": {
          setSectionNavTreeData(settingsSectionNavTree);
          break;
        }
        case "offers": {
          setSectionNavTreeData(campaignSectionNavTree);
          break;
        }
        case "ofco-ad": {
          setSectionNavTreeData(adSectionNavTree);
          break;
        }
        case "suco-ad": {
          setSectionNavTreeData(adSectionNavTree);
          break;
        }
        case "cart": {
          setSectionNavTreeData(cartSectionNavTree);
          break;
        }
        default: {
          setSectionNavTreeData(cartSectionNavTree);
          break;
        }
      }
    };
    initializeSidebar();
  }, [pageRoute]);

  return (
    <div className="w-44 desktop:w-64 laptop:flex  mobile:hidden flex-col flex-none ">
      <div className="flex items-center h-20 desktop:h-24 flex-none justify-center desktop:justify-start desktop:px-8  ">
        <div className="laptop:px-4 desktop:px-0">
          <img
            className=""
            width="100%"
            src="https://assets.cornercart.io/logo/corner-logo-shadow.svg"
          />
        </div>
      </div>
      <div className="relative flex flex-col grow pb-3">
        <div className="grow">
          <Tree
            tree={sectionNavTreeData}
            offsetPerLevel={10}
            renderItem={({ item, provided }) => {
              return (
                <div
                  className="cursor-pointer"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <SectionLink item={item} />
                </div>
              );
            }}
          />
        </div>
        <div>
          <div className="flex flex-col items-center">
            {pageRoute !== "settings" && (
              <NavLink to="/settings" strict>
                <Button
                  className="bg-opacity-25 mb-3"
                  icon={<RiSettings3Fill />}
                  type="link"
                >
                  Settings
                </Button>
              </NavLink>
            )}

            {/* <Button
              className="bg-opacity-25"
              onClick={() => {
                handleScreenMaximise();
              }}
              icon={
                isFullscreen ? <RiFullscreenExitFill /> : <RiFullscreenFill />
              }
              type="white-ghost"
            >
              {isFullscreen ? "Exit full screen" : "Go to full screen"}
            </Button> */}

            {/* <span className="text-xs justify-center flex items-center  text-black opacity-30 mb-3 self-center">
            <RiStore2Fill className="mr-2" /> {workspace.data.storeUrl}
          </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionNav;
