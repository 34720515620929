import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiCheckDoubleFill,
  RiCheckboxCircleFill,
  RiShoppingCartFill,
  RiShieldCheckFill,
} from "react-icons/ri";
import { FaChessKnight, FaChessPawn } from "react-icons/fa";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";
import { useGraphQl } from "../contexts/GraphqlClient";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { queryBillingInfo, activateFreePlan, choosePlan } from "../api/billing";
import toaster from "toasted-notes";

/**
 * A modal which shows plans which the client could upgrade to
 * @component
 * @param {Number} hightlightPlan - The plan which should be highlighted
 * @param {String} title - Title of the modal
 * @param {Boolean} isOpen - Boolean which control whether the modal is open or not
 * @param {Function} onClose - What happens on Close
 * @param {Boolean} nonClosable - if this is true the option to close the modal will be removed. This wil be true in cases where we have to force the customer to choose a plan
 */
const CocaPricingModal = ({
  hightlightPlan,
  title,
  isOpen,
  onClose,
  nonClosable,
}) => {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const { data, refetch } = useQuery(
    "queryBillingInfoCoca",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${3}`, // hardcoded app because this particular modal only needs SUCO billing info
        sourceId: `${workspace.data?.sourceid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 1) }
  );

  const freePlanActivation = async (input) => {
    await graphQL(activateFreePlan, {
      appId: `${3}`,
      planId: `${input.id}`,
      sourceId: `${workspace.data?.sourceid}`,
    });
  };

  const paidPlanActivation = async (input) => {
    const returnData = await graphQL(choosePlan, {
      appId: `${3}`,
      planId: `${input.id}`,
      sourceId: `${workspace.data?.sourceid}`,
    });

    return returnData;
  };

  const [freePlanActivationMutation] = useMutation(freePlanActivation, {
    onError: (err) => {
      console.error("Error in suco freePlanActivationMutation ", err);
      window.Rollbar.error("Error in suco freePlanActivationMutation ", err);
    },
    onSuccess: () => {
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      queryCache.invalidateQueries("queryBillingInfoCoca");
      refetch();
      location.reload();
    },
  });

  /**
   * A ui element which features a single plan
   * @component
   * @param {Number} planId - The plan which should be highlighted
   * @param {('free'|'monthly'|'yearly')} type - Frequency of billing or whenther its completely free
   * @param {Boolean} hightlight - Boolean which control whether the plan card should be highlighted or not
   * @param {Node} icon - Icon which needs to be displayed abocve the plan card
   * @param {String} planName - Name of the pLan
   * @param {Number} amount - Price of the plan
   * @param {String} cta -CTA text
   * @param {Number} freeTrial - Number of free trial days
   * @param {Number} moneyBackDays - Number of money back refund days
   * @param {String[]} features - Array of all strings which needed to be displayed under each plan
   */
  const PlanCard = ({
    planId,
    type,
    hightlight,
    icon,
    planName,
    amount,
    cta,
    freeTrial,
    moneyBackDays,
    features,
  }) => {
    const [paidPlanActivationMutation] = useMutation(paidPlanActivation, {
      onError: (err) => {
        console.log("error", err);
        window.Rollbar.error("Error in socu paidPlanActivationMutation ", err);
      },
      onSuccess: (data) => {
        window.location.href = data?.choosePlan?.confirmationUrl;
      },
      onMutate: () => {
        setIsLoading(true);
      },
    });

    const isActive = planId === data?.billingInfo?.planId;
    const [isLoading, setIsLoading] = useState(false);
    const handleButtonClick = () => {
      if (type === "free")
        freePlanActivationMutation({
          id: planId,
        });
      else
        paidPlanActivationMutation({
          id: planId,
        });
    };

    return (
      <div
        className={`border p-5 rounded-md mr-3 w-1/3 ${
          isActive && "opacity-50"
        } ${hightlight && "relative shadow-2xl"}`}
      >
        <p className="font-bold flex items-center mb-5 text-center">
          <span className="p-2 border border-violet-700 text-violet-700 rounded-md mr-3 text-sm ">
            {icon}
          </span>
          {planName}
        </p>
        <div className="flex mt-3 items-baseline">
          <h1 className="text-5xl font-thin text-violet-700 leading-10 ">
            {type === "free" ? "Free" : `$${amount}`}
          </h1>
          <p className="text-xs text-gray-500">
            {type !== "free" && "/ month"}
          </p>
        </div>
        <Button
          className="mt-5"
          onClick={() => {
            handleButtonClick();
          }}
          loading={isLoading}
          type={isActive ? "link" : "primary"}
          icon={isActive ? <RiCheckboxCircleFill /> : <RiShoppingCartFill />}
        >
          {isActive ? "Your Current Plan" : cta}
        </Button>
        {freeTrial && !isActive && (
          <div>
            <small className="text-sm">With {freeTrial} days free trial</small>
          </div>
        )}
        {moneyBackDays && !isActive && (
          <div className="flex items-center rounded-lg bg-violet-100 text-violet-700 px-2 py-1 mt-3">
            <div className=" mr-3 text-2xl  ">
              {" "}
              <RiShieldCheckFill />
            </div>
            <div className="text-sm">
              <p>If you're unhappy, we offer</p>
              <p className="font-bold">
                {" "}
                {moneyBackDays} days money back guarantee
              </p>
            </div>
          </div>
        )}
        <div className="mt-6 pt-6 border-t">
          {features.map((feature) => {
            return (
              <div key={feature} className="flex items-center mt-2 ">
                <span className="text-xl text-violet-400 mr-2">
                  <RiCheckDoubleFill />
                </span>
                <p>{feature}</p>{" "}
              </div>
            );
          })}
        </div>
        {hightlight && (
          <div className="animate-pulse pointer-events-none absolute top-0 right-0 border-4 border-violet-700 rounded-md h-full w-full opacity-75" />
        )}
      </div>
    );
  };
 

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          width="x-large"
          actions={[
            {
              isDisabled: nonClosable,
              text: "Cancel",
              onClick: () => {
                onClose();
              },
            },
          ]}
          onClose={() => {
            nonClosable && onClose(false);
          }}
        >
          <div className="text-center text-2xl font-bold mt-8">
            {title ? title : "Select a plan"}
          </div>
          <div className="flex w-full justify-center  mt-8 ">
            {/* <PlanCard
              icon={<FaChessPawn />}
              planName="Starter"
              planId={12}
              type="free"
              cta="Select"
              features={[
                "Add to Cart Bar",
                "Cart Widget",
                "Cart Widget Solid & Gradient Banner",
                "Unlimited Interaction",
                "Unlimited Checkouts",
              ]}
            /> */}
            <PlanCard
              hightlight={hightlightPlan === 5}
              icon={<FaChessKnight />}
              planName="Pro Plan"
              // moneyBackDays={30}
              planId={13}
              amount={10}
              freeTrial={7}
              type="monthly"
              cta="Select"
              features={[
                "Advanced Color Customisation",
                "Mobile-Specific Customisations",
                "Cart Widget Themes",
                "Optional Cart Notes",
                // "Smart Upsells",
                "Custom CSS",
                "Custom HTML in Cart Widget",
                // "Custom Click actions",
                "Multi-Language Support",
                "Remove Corner Branding",
                "Priority Support",
                "Plus everything in Free",
              ]}
            />
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CocaPricingModal;
