import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import CartGoals from "./CartGoals";

export default function WidgetRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 3)
      workspace.data.changeCurrentAppID(3);
  }, [workspace]);

  const ComingSoon = () => {
    return (
      <PageViewContainer title="Cart Goals">
        <div className="h-full flex items-center justify-center flex-col">
          <div className="flex justify-center my-8">
            <img
              src="https://assets.cornercart.io/coca-v2/images/empty-states/dynamic-accordion.png"
              alt=""
            />
          </div>
          <div className="text-center max-w-xl">
            <h3 className="font-bold text-gray-700 mb-3">Comming Soon </h3>
            <p className="text-gray-800">
              Cart Goals lets you set goals for your customers that unlock
              specific rewards. (Add $X to unlock = free shipping)
            </p>
            <div className="border p-2 mt-3 rounded-lg bg-gray-100">
            <small className="text-xs text-gray-500">
              This is a pro freature that we are testing with a selected number
              of stores. If you would like to be an ealry adopter reach out to
              us via chat
            </small>
            </div>
          </div>
        </div>
      </PageViewContainer>
    );
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}/cart-goals/`}
          component={
            workspace.data?.feature_flag.apps?.cornercart?.cart_goals
              ? CartGoals
              : ComingSoon
          }
        />
        <Route exact path={path}>
          <Redirect to={`/cart/cro/cart-goals/`} />
        </Route>
      </Switch>
    </>
  );
}
